import { Button } from 'antd';
import { DownOutlined, UpOutlined } from '@/components2/Icons';
import React, { ReactElement, useState } from 'react';
import AirportsTable from './AirportsTable';

function Airports({ airports }): ReactElement {
  const [showAllAirports, setShowAllAirports] = useState(false);
  return (
    <div className="container flex flex-col justify-center text-center w-full md:w-3/4 gap-4 my-4 mx-auto ">
      <div
        className={`overflow-hidden ${
          showAllAirports ? 'h-auto pt-1.5' : 'h-14'
        } flex flex-col px-2 justify-center text-center border-y border-gray-300`}
      >
        <div
          className={`
          w-full flex flex-row justify-between items-center
          ${showAllAirports ? '-pb-4' : 'mt-0'}
          `}
        >
          <h2 className="text-sm md:text-base font-bold text-left">
            Discover where you can park with On Air Parking
          </h2>
          <Button
            size="large"
            className="border-none bg-transparent md:text-xl text-primary"
            onClick={() => setShowAllAirports(!showAllAirports)}
          >
            {showAllAirports ? <UpOutlined /> : <DownOutlined />}
          </Button>
        </div>
        <div
          className={`w-full pt-4 ${showAllAirports ? 'visible' : 'hidden'}`}
        >
          <AirportsTable
            airports={airports}
            closeAirports={() => setShowAllAirports(false)}
          />
        </div>
      </div>
    </div>
  );
}

export default Airports;
